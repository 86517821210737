<template>
  <v-dialog v-model="showDialog" max-width="400px">
    <v-card>
      <v-card-title>
        <h4>{{ titulo }}</h4>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="message">{{ mensagem }}</div>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-spacer></v-spacer>
        <v-btn
          v-if="showConfirmDialog"
          color="primary"
          @click="confirmar"
          style="background-color: #757575; color: white; margin-right: 0.3cm"
        >
          Sim
        </v-btn>
        <v-btn
          v-if="showConfirmDialog"
          color="error"
          @click="cancelar"
          style="background-color: #757575; color: white"
        >
          Não
        </v-btn>
        <v-btn
          v-else
          color="primary"
          @click="fecharDialog"
          style="background-color: #757575; color: white"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      showDialog: false,
      mensagem: "",
      titulo: "",
      callback: null,
      showConfirmDialog: false, // Novo atributo para controlar a exibição dos botões de confirmação
    };
  },
  methods: {
    showMsg(titulo, mensagem) {
      this.titulo = titulo;
      this.mensagem = mensagem;
      this.showConfirmDialog = false; // Não mostra os botões de confirmação
      this.showDialog = true;
    },
    showConfirm(titulo, mensagem, callback) {
      this.titulo = titulo;
      this.mensagem = mensagem;
      this.callback = callback;
      this.showConfirmDialog = true; // Mostra os botões de confirmação
      this.showDialog = true;
    },
    confirmar() {
      this.showDialog = false;
      this.titulo = "";
      this.mensagem = "";
      if (typeof this.callback === "function") {
        this.callback(true);
      }
    },
    cancelar() {
      this.showDialog = false;
      this.titulo = "";
      this.mensagem = "";
      if (typeof this.callback === "function") {
        this.callback(false);
      }
    },
    fecharDialog() {
      this.showDialog = false;
      this.titulo = "";
      this.mensagem = "";
      this.showConfirmDialog = false; // Reseta o valor para a próxima exibição
    },
  },
};
</script>

<style scoped>
.message {
  font-size: 16px;
  margin-bottom: 16px;
}
</style>
