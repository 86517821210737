import { httpCadastro } from "../plugins/http";

let endpoint = "/v1/cadastro";

export default {
  getEstados: () => {
    let url = endpoint + "/estado";

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  getCidades: (idEstado) => {
    let url = endpoint + "/cidade/" + idEstado;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },
};
