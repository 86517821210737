import { httpCadastro } from "../plugins/http";

let endpoint = "/v1/cadastro";

export default {
  getTransportadores: (tipoFiltro, valor, status, tipo) => {
    let url =
      endpoint +
      "/participante" +
      "/busca?tipoFiltro=" +
      tipoFiltro +
      "&valor=" +
      valor +
      "&status=" +
      status +
      "&tipo=" +
      tipo;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  getTransportadoresUsuario: (idUsuario) => {
    let url =
      endpoint +
      "/participante" +
      "/busca-usuario?idUsuario=" +
      idUsuario;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  getCpfCnpj: (cpfCnpj, tipo) => {
    let url =
      endpoint + "/participante/cpf-cnpj?cpfCnpj=" + cpfCnpj + "&tipo=" + tipo;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  insertTransportador: (transportadorData) => {
    return httpCadastro.post(endpoint + "/participante", transportadorData, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  updateTransportador: (id, transportadorData) => {
    return httpCadastro.put(`${endpoint + "/participante"}/${id}`, transportadorData, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  liberarPreCadastro: (id) => {
    return httpCadastro.put(`${endpoint + "/participante/pre-cadastro"}/${id}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  salvarUsuario: (usuarioData) => {
    let url = endpoint + "/participante/usuario";

    return httpCadastro.post(url, usuarioData, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  salvarCpfMotorista: (cpfMotorista) => {
    let url = "https://api.auth.workinnovation.com.br/v1/api/seg/usuarios/condutor/parametrizacao";

    return httpCadastro.post(url, cpfMotorista, {
      headers: { 
        "Authorization": "Bearer " + localStorage.getItem("token")
      },
    });
  },

  salvarConfigAverbacao: (idTransportador, configData) => {
    return httpCadastro.put(
      `${endpoint + "/participante-config"}/${idTransportador}`,
      configData,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  getConfigAverbacao: (idTransportador) => {
    let url = endpoint + "/participante-config/" + idTransportador;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  salvarCertificado: (arquivo, cnpj, senha) => {
    const formData = new FormData();
    formData.append("arquivo", arquivo);
    formData.append("cnpj", cnpj);
    formData.append("senha", senha);

    return httpCadastro
      .post(`${endpoint + "/certificado"}`, formData, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getPartCertificado: (tipoFiltro, valor, status, tipo, idUsuario) => {
    let url =
      endpoint +
      "/participante" +
      "/participante-certificado?tipoFiltro=" +
      tipoFiltro +
      "&valor=" +
      valor +
      "&status=" +
      status +
      "&tipo=" +
      tipo +
      "&idUsuario=" +
      idUsuario      
      ;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },


  getMotoristas: (idParticipante) => {
    let url =
      endpoint +
      "/participante-motorista" +
      "/busca?idParticipante=" +
      idParticipante;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  getCpfMotorista: (cpf) => {
    let url = endpoint + "/motorista/cpf?cpf=" + cpf;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  deletarMotorista: (id) => {
    return httpCadastro.delete(`${endpoint}/participante-motorista/${id}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  insertMotorista: (participanteMotorista) => {
    return httpCadastro.post(
      endpoint + "/participante-motorista",
      participanteMotorista,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  updateMotorista: (id, participanteMotorista) => {
    return httpCadastro.put(
      `${endpoint + "/participante-motorista"}/${id}`,
      participanteMotorista,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  getVeiculos: (idParticipante) => {
    let url =
      endpoint +
      "/participante-veiculo" +
      "/busca?idParticipante=" +
      idParticipante;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  getPlacaVeiculo: (placa) => {
    let url = endpoint + "/veiculo/placa?placa=" + placa;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  deletarVeiculo: (id) => {
    return httpCadastro.delete(`${endpoint}/participante-veiculo/${id}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  insertVeiculo: (participanteVeiculo) => {
    return httpCadastro.post(
      endpoint + "/participante-veiculo",
      participanteVeiculo,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  updateVeiculo: (id, participanteVeiculo) => {
    return httpCadastro.put(
      `${endpoint + "/participante-veiculo"}/${id}`,
      participanteVeiculo,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },
};
